<template>
  <b-container
    fluid
  >
    <b-row>
      <b-col
        cols="12"
      >
        <b-card
          title="Payment Requests"
          sub-title="My Check Approvals"
          class="shadow-sm"
        >
          <b-row>

            <b-col
              cols="12"
              md="2"
              class="p-2"
            >
              <PaleCard
                label="Pending Checks"
                variant="warning"
                :count="approvals.pending_checks"
                :loading="fetching"
                :link="{ name: 'cmo.check-approvals', params: { cmo: 'pending' }}"
              />
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="p-2"
            >
              <PaleCard
                label="Processed Checks"
                variant="success"
                :count="approvals.processed_checks"
                :loading="fetching"
                :link="{ name: 'cmo.check-approvals', params: { cmo: 'histories' }}"
              />
            </b-col>

          </b-row>
        </b-card>
      </b-col>
    </b-row>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { CMODashboardService } from '@/services'
import PaleCard from '@/components/custom/PaleCard'

export default {
  name: 'CmoDashboard',

  middleware: ['auth', 'cmo'],

  components: {
    PaleCard
  },

  metaInfo: () => ({
    title: 'Dashboard'
  }),

  data () {
    return {
      fetching: false,
      approvals: {
        pending_checks: 0,
        processed_checks: 0
      }
    }
  },

  mounted () {
    core.index()
    this.getStatistics()
  },

  methods: {

    async getStatistics () {
      this.fetching = true
      return await CMODashboardService.get().then(({ data }) => {
        this.approvals.pending_checks = data.pending_checks
        this.approvals.processed_checks = data.processed_checks
        this.fetching = false
      })
    }

  }
}
</script>
